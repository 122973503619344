import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OneInput from "./OneInput";
import KABodySidebarThree from "../KABody/KABodySidebarThree";
import KAGatedInline from "../KAGatedInline";
import GatedEmailCollection from "./GatedEmailCollection";

function SidebarThreeBody({
  responseData,
  handeLinkClick,
  handRatingTake,
  isLoading,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
}) {
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const [currentAction, setCurrentAction] = useState(null);
  const [isVisible, setIsVisible] = useState(true); // State to manage visibility

  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const actions = useSelector((state) => state.flipcard.data.flipCard.actions);
  const showActionAfterResponses = useSelector(
    (state) => state?.flipcard?.data?.config?.showActionAfterResponses
  );

  const numberOfKaQuestionsSubmitted = useSelector(
    (state) => state.app.numberOfKaQuestionsSubmitted
  );

  const gatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const shouldRenderGatedInline =
    !isCtaTaken &&
    actions.length > 0 &&
    numberOfKaQuestionsSubmitted > showActionAfterResponses;

  useEffect(() => {
    if (shouldRenderGatedInline && !currentAction && actions?.length > 0) {
      setCurrentAction(actions[0]);
    }
  }, [shouldRenderGatedInline, actions, currentAction]);

  if (!isRagCollapsed) {
    return (
      <>
        <KABodySidebarThree
          responseData={responseData}
          handeLinkClick={handeLinkClick}
          handRatingTake={handRatingTake}
          isLoading={isLoading}
          inputQuery={inputQuery}
          shouldLastQuestionBlur={
            gatedEngagement &&
            shouldRenderGatedInline &&
            currentAction?.actionData?.actionType !== "link"
          }
        />
        <div className="kaSidebarThreeBottomChat">
          {shouldRenderGatedInline && currentAction?._id && isVisible && (
            <KAGatedInline
              currentAction={currentAction}
              setIsVisible={setIsVisible}
            />
          )}

          {shouldRenderGatedInline &&
          currentAction?._id &&
          isVisible &&
          currentAction?.actionData?.actionType !== "link" &&
          isVisible ? (
            <GatedEmailCollection currentAction={currentAction} />
          ) : (
            <OneInput
              handleGenerateAnswer={handleGenerateAnswer}
              inputQuery={isLoading ? "" : inputQuery}
              setInputQuery={setInputQuery}
              isLoading={isLoading}
              currentAction={currentAction}
            />
          )}
        </div>
      </>
    );
  }

  return <></>;
}

export default SidebarThreeBody;
