import React from "react";
import KALogo from "../KALogo";
import { useSelector } from "react-redux";
import OneInput from "./OneInput";
import KALoader from "../KALoader";
import SidebarOneFAQ from "./SidebarOneFAQ";
import useGetWindowWidth from "../../../../../utils/controllers/useGetWindowWidth";
import SidebBarOneInput from "./SidebBarOneInput";

function SidebarOneTopBar({
  responseData,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
  isLoading,
  error,
}) {
  const width = useGetWindowWidth();

  const title = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.title
  );

  const topbarWidth = () => {
    if (responseData?.length > 0 || width <= 500) {
      return "100%";
    }
    return "70%";
  };

  return (
    <>
      <div
        className="kaTopBarOne"
        style={{
          textAlign: responseData?.length > 0 ? "left" : "center",
          width: topbarWidth(),
        }}
      >
        <KALogo />
        <h3>{title || "Ask a question related to article."}</h3>
        <KALoader
          isLoading={isLoading}
          responseData={responseData}
          error={error}
        />
        <SidebBarOneInput
          handleGenerateAnswer={handleGenerateAnswer}
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          isLoading={isLoading}
        />
        <SidebarOneFAQ
          handleGenerateAnswer={handleGenerateAnswer}
          responseDataLength={responseData?.length}
        />
      </div>
    </>
  );
}

export default SidebarOneTopBar;
