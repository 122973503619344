import React, { useEffect, useState } from "react";
import KALogo from "../KALogo";
import { useSelector } from "react-redux";
import OneInput from "./OneInput";
import KALoader from "../KALoader";
import SidebarOneFAQ from "./SidebarOneFAQ";
import useGetWindowWidth from "../../../../../utils/controllers/useGetWindowWidth";
import SidebBarOneInput from "./SidebBarOneInput";

function SidebarThreeTopBar({
  responseData,
  handleGenerateAnswer,
  inputQuery,
  setInputQuery,
  isLoading,
  error,
}) {
  const [currentAction, setCurrentAction] = useState(null);

  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const actions = useSelector((state) => state.flipcard.data.flipCard.actions);
  const showActionAfterResponses = useSelector(
    (state) => state?.flipcard?.data?.config?.showActionAfterResponses
  );

  const numberOfKaQuestionsSubmitted = useSelector(
    (state) => state.app.numberOfKaQuestionsSubmitted
  );

  const gatedEngagement = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );

  const shouldRenderGatedInline =
    !isCtaTaken &&
    actions.length > 0 &&
    numberOfKaQuestionsSubmitted >= showActionAfterResponses;

  useEffect(() => {
    if (shouldRenderGatedInline && !currentAction && actions?.length > 0) {
      setCurrentAction(actions[0]);
    }
  }, [shouldRenderGatedInline, actions, currentAction]);
  const width = useGetWindowWidth();

  const title = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.title
  );

  const topbarWidth = () => {
    if (responseData?.length > 0 || width <= 500) {
      return "100%";
    }
    return "70%";
  };

  return (
    <>
      <div
        className="kaTopBarOne"
        style={{
          textAlign: responseData?.length > 0 ? "left" : "center",
          width: topbarWidth(),
        }}
      >
        <KALogo />
        <h3>{title || "Ask a question related to article."}</h3>

        {responseData?.length === 0 && (
          <>
            <KALoader
              isLoading={isLoading}
              responseData={responseData}
              error={error}
            />
            <SidebBarOneInput
              handleGenerateAnswer={handleGenerateAnswer}
              inputQuery={inputQuery}
              setInputQuery={setInputQuery}
              isLoading={isLoading}
              responseData={responseData}
            />
          </>
        )}

        <SidebarOneFAQ
          handleGenerateAnswer={handleGenerateAnswer}
          responseDataLength={responseData?.length}
        />
      </div>
    </>
  );
}

export default SidebarThreeTopBar;
